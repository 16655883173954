.connect_blockchain {
    display: flex;
    align-items: center;
    min-height: 100svh;
    padding: 0 6.5%;
    position: relative;
}

.connect_blockchain .d-flex {
    width: 100%;
}

.connect_blockchain .inner_wrapper{
    align-items: center;
    justify-content: center;
}
.connect_blockchain .left_text {
    width: 40%;
    position: relative;
    height: calc(6.67vw + 24px + 6vw + 50px);
}

.connect_blockchain .left_text > div[role="button"] {
    height: unset !important;
}

.connect_blockchain img.text {
    width: 90%;
    margin-bottom: 50px;
}

.connect_blockchain .left_text .details {
    font-size: 1.375em;
    font-weight: 300;
    line-height: 1.16;
    color: #232424;
    margin: 0 0 50px;
    width: 100%;
    letter-spacing: 1px;
}
.connect_blockchain .left_text .details .withBg {
    color: #244C56;
    background-color: #D9D9D9;
    border: 1px solid #F3F3F3;
    padding: 0 5px;
    margin: 0 5px;
    font-style: italic;
    font-weight: 400;
}
.connect_blockchain .left_text .details .bi {
    font-style: italic;
}
.connect_blockchain .right_video {
    width: 60%;
}
.connect_blockchain .right_video video {
    width: 110%;
}
.connect_blockchain .btn {
    background-color: #00a68a;
    padding: 7.5px;
    padding-left: 35px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    font-size: 1.375em;
    font-weight: 300;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    /*opacity: 0;*/
    transition: all 1s;
    transition-delay: .8s;
}

.connect_blockchain .btn img {
    margin-left: 14px;
}

.connect_blockchain .lottieBtn{
    transition: all 1s;
    display: flex;
    transition-delay: 1.5s;
    width: fit-content;
    opacity: 0;
}

.connect_blockchain .mobile {
    display: none;
}

@media (max-width: 1750px) {
    .connect_blockchain .right_video video {
        max-width: 110%;
        margin-left: -7%;
    }
}

@media (max-width: 1499px) {
    .connect_blockchain {
        margin-bottom: 100px;
        padding-bottom: 0;
    }
}
@media (max-width: 1199px) {
    .connect_blockchain {
        padding-bottom: 60px;
    }
}

@media (max-width: 1023px) {
    .connect_blockchain .left_text {
        width: 100%;
        position: relative;
    }
    .connect_blockchain .right_video {
        width: 100%;
    }
    .connect_blockchain .right_video video {
        max-width: 100%;
        margin-left: 0;
    }

    .connect_blockchain .inner_wrapper {
        padding: 0px 40px;
    }
    .connect_blockchain {
        padding-bottom: 150px;
    }
    .textslider_colin .col > div {
        width: 450px !important;
        height: 270px !important;
    }
    .connect_blockchain .inner_wrapper {
        flex-direction: column-reverse;
    }
    .connect_blockchain {
        padding-bottom: 0;
    }
    .connect_blockchain {
        padding-top: 440px;
    }
    .connect_blockchain .left_text {
        margin-bottom: 50px;
    }
    .connect_blockchain .left_text .details {
        width: 100%;
    }
    .connect_blockchain .mobile {
        display: block;
        width: 100%;
    }
    .connect_blockchain .right_video video {
        display: none;
    }
}

@media (max-width: 767px) {
    .connect_blockchain .right_video {
        width: 100%;
        margin-top: -30%;
        margin-bottom: -50%;
    }

    .connect_blockchain .left_text {
        width: 100%;
        margin: 0;
    }

    .connect_blockchain .inner_wrapper {
        padding: 0px 20px;
    }
    .textslider_colin .next_prev button img {
        max-width: 35px;
    }
    .textslider_colin .next_prev {
        bottom: calc(100% + 20px);
    }
    .connect_blockchain {
        padding-top: 0px;
        margin: 0;
    }
    .textslider_colin .col > div {
        width: 280px !important;
        height: 190px !important;
    }
    .textslider_colin {
        min-height: 140px;
        margin-top: 80px;
    }

    .connect_blockchain img.text {
        width: 100%;
    }
    .connect_blockchain .left_text {
        height: 65vw;
    }
}
