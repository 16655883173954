:root {
}
html,
body,
br,
hr,
div,
span,
a,
object,
iframe,
ul,
ol,
dl,
li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
q,
address,
small,
big,
cite,
dfn,
ins,
del,
i,
em,
b,
strong,
sup,
sub,
strike,
pre,
code,
samp,
kbd,
var,
tt,
form,
fieldset,
legend,
label,
input,
textarea,
option,
.nobox {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  color: #464646;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

blockquote,
q {
  quotes: none;
}
br {
  height: 0;
}
ul,
ol,
dl,
li {
  margin: 0;
  padding: 0;
  color: #555555;
  /*font-size: 0.875em;*/
  /*line-height: 24px;*/
}
html,
body {
  height: 100%;
  -webkit-appearance: none;
  border: none;
  -webkit-text-size-adjust: none;
  font-size: 16px;
}
body {
  position: relative;
}

/* Class for clearing floats */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}
.cf:after,
.cf:before {
  display: table;
  content: "";
}
.cf:after {
  clear: both;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* 2. Define Base Typography
------------------------------------------------------------------------------*/

/* Create the Default Headers  */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #00a68a;
}

/* Specific Header Settings */
h1 {
  font-weight: bold;
  font-size: 2.25em;
}
h2 {
  font-weight: bold;
  font-size: 1.5em;
}
h3 {
  font-size: 1.125em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.875em;
}
h6 {
  font-size: 0.75em;
}

/* Create the Default Paragraphs */
p {
  color: #00a68a;
  font-size: 1.25em;
  line-height: 1.75em;
  letter-spacing: calc(1em / 8);
}

/* Other Typo */
strong {
  font-weight: bold;
}
hr {
  border: 0 #464646 solid;
  border-top-width: 1px;
  clear: both;
  height: 0;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}

/* To make FontSmooth in MAC */
.mac * {
  -webkit-font-smoothing: antialiased;
}

/* 3. Images
------------------------------------------------------------------------------*/

/* Remove border around linked images */
img {
  border: 0;
  border-style: none;
}

/* 4. Links
------------------------------------------------------------------------------*/

/* Default Link Types */
a {
  color: #00a68a;
  font-weight: 300;
  line-height: 44px;
  font-size: 1.125em;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
}
a:active {
  color: #e8941a;
}
a:focus {
  outline: none;
}
a.current {
  color: #e8941a;
}

/* 5. Forms
------------------------------------------------------------------------------*/

input {
  line-height: normal;
}
input[type="email"],
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
  border: none;
  -webkit-text-size-adjust: none;
  padding: 5px;
  border: 1px solid #cbcbcb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
textarea {
  -webkit-appearance: none;
  border: none;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #cbcbcb;
  display: block;
  overflow: auto;
}
input[type="email"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: #ccc;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  border: none;
  -webkit-text-size-adjust: none;
  background: #cbcbcb;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: #696969;
}
:-moz-placeholder {
  color: #696969;
}
::-moz-placeholder {
  color: #696969;
}
:-ms-input-placeholder {
  color: #696969;
}
::-ms-input-placeholder {
  color: #696969;
}
:placeholder-shown {
  color: #696969;
}

/* Removes fieldset borders. even on Opea 7 */
fieldset {
  border: 1px solid transparent;
}

/* 6. Tables
------------------------------------------------------------------------------*/

/* Table Fixes */
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td {
  text-align: left;
  font-weight: normal;
}

/* 7. Framework
------------------------------------------------------------------------------*/

/* Common CSS */
/*common margin style*/
.m0 {
  margin: 0 !important;
}
.mr20 {
  margin-right: 20px;
}
.mt20 {
  margin-top: 20px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt50 {
  margin-top: 50px !important;
}

/*all padding zero*/
.p0 {
  padding: 0 !important;
}
.pt20 {
  padding-top: 20px;
}

/*common display style*/
.dis-b {
  display: block;
}
.pos_rel {
  position: relative;
}
.hidden {
  display: none !important;
}

/*border*/
.no_bdr {
  border: none !important;
}

/*common fonts style*/
.f16 {
  font-size: 1em !important;
}
.f18 {
  font-size: 1.125em !important;
}
.fbold {
  font-weight: bold;
}

/*transition effect*/
.tra,
a,
.in,
.sub {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
/* opacity effect */
.opacity1 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.opacity0 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

body * {
  font-family: "Neue Haas Grotesk Display Pro";
}
body {
  padding-top: 86px;
}
header {
  padding: 20px 6.5%;
  box-shadow: 0px 4px 4px #00a68a1a;
  position: fixed;
  width: 100%;
  top: -150px;
  z-index: 9999;
  left: 0;
  transition: 0.8s linear all;
  background-color: #fff;
}
header a {
  color: #041E19;
}
header.active {
  top: 0;
  transition: 0.5s ease all;
}
header.active.isactive {
  transform: translateY(-110%);
  transition: 0.5s ease all;
}
header .brand {
  line-height: 1.5;
  font-size: 1.625em;
  color: #041E19;
}
header .right_col {
}
header ul {
  list-style: none;
}
header ul li {
  margin-right: 42px;
}
header ul li:last-child {
  margin-right: 0px;
}
header ul li a {
  line-height: 46px;
  position: relative;
}
header ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 27px;
  height: 2px;
  background-color: #041E19;
  bottom: -4px;
  transition: 0.4s ease all;
  transform: scale(0);
  transform-origin: left center;
}
header ul li a:hover::after,
header ul li a.active::after {
  transform: scale(1);
}
header .wallet {
  border: 1px solid #00a68a;
  color: #00a68a;
  border-radius: 32px;
  margin-left: 53px;
  padding: 0px 20.27px;
}


/* new css */
html {
  /*scroll-snap-type: y mandatory;*/
  scroll-behavior: smooth
}

.scroll-snap {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}


.inner_wrapper {
  max-width: 1660px;
  margin: 0 auto;
}


.particle_div {
  /*background-image: url(../src/assets/images/bg_particle.jpg);*/
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.particle_div .middle_section {
  text-align: center;
  margin: auto;
  padding: 115px 0px;
}
.particle_div .middle_section h5 {
  color: #ffffff;
  font-size: 3.4375em;
  font-weight: 300 !important;
  line-height: 1.16;
  margin-bottom: 77px;
  text-align: left;
}
.particle_div .middle_section .infotext_box {
  padding-bottom: 72px;
  margin: 0 auto;
}
.particle_div .middle_section .infotext_box:last-child {
  padding-bottom: 0;
}

.btn_global {
  background-color: #ffffff;
  border-radius: 60.299px;
  color: #00a68a;
  font-size: 1.375em;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px 10px 25px;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
}
.btn_global:hover {
  background-color: #00a68a;
  color: #ffffff;
}
.btn_global img {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}
.particle_div .middle_section .infotext_box:first-child {
  max-width: 620px;
}

.onchain_container {
  text-align: center;
  padding-top: 167px;
  padding-bottom: 205px;
}
.onchain_container .textWrap {
  height: 180px;
}

.onchain_container .textWrap.second {
  height: 120px;
}
.onchain_container h4 {
  color: #041E19;
  line-height: 1.33;
  font-size: 2.8125em;
  font-weight: 100;
  max-width: 1000px;
  margin: 0 auto;
  /*height: 180px;*/
}
.for {
  width: 75px;
  margin-top: 8px;
}
.onchain_container h4.second {
  height: 120px;
}
.onchain_container h4 em {
  font-weight: 400;
  color: #00a68a;
}
.onchain_container .learn_more {
  background-color: #00a68a;
  padding: 7.5px;
  padding-left: 35px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  font-size: 1.375em;
  font-weight: 300;
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 62px;
}
.onchain_container .learn_more img {
  margin-left: 14px;
}

.onchain_container .newBtn {
  margin: 62px auto 0;
}
/* .onchain_container .learn_more:hover {
  opacity: 0.7;
} */

.subscribe_wrapper {
  background-image: url(../src/assets/images/sub_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  padding: 72px 10px 104px;
  background-size: cover;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: 36px;
  text-align: center;
  margin-bottom: 140px;
}
.subscribe_wrapper h4 {
  color: #f0f7f6;
  font-size: 2.8125em;
  font-style: normal;
  font-weight: 100;
  /*line-height: 55px;*/
  margin-bottom: 95px;
}
.subscribe_wrapper .subscribe_inner {
  max-width: 687px;
  margin: 0 auto;
}
.subscribe_wrapper .from_group {
  position: relative;
}
.subscribe_wrapper .from_group .in_field {
  width: 100%;
  background-color: #ffffff;
  border: 0px solid transparent;
  border-radius: 51.044px;
  height: 60px;
  padding-left: 36px;
  color: #032221;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 1.0634375em;
  font-weight: 300;
}
.subscribe_wrapper .from_group .newBtn {
  position: absolute;
  right: 0px;
  bottom: 0;
  top: 0;
  margin: 2px;
  height: calc(100% - 4px);
  font-weight: 300;
}

.subscribe_wrapper .from_group .submit_btn div {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/* footer css */
footer {
  padding: 24px 56px;
}
footer .top-footer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 60px 32px;
  margin-bottom: 25px;
}
footer .top-footer .brand-logo a {
  line-height: 1;
  font-size: 1.5em;
  color: #fff;
}
footer .bottom-footer ul :is(li, a) {
  line-height: 0;
  font-size: 0;
}
footer .bottom-footer ul {
  list-style: none;
  display: flex;
  gap: 0 16px;
  align-items: center;
}
footer .bottom-footer {
  padding: 0 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .copyright {
  color: rgba(241, 247, 246, 0.3);
  text-align: right;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
/* footer .bottom-footer ul li:hover a svg {
  opacity: 0.5;
} */
footer .bottom-footer ul li:hover a svg {
  transition: 0.4s ease all;
}
.mobile_toggle {
  display: none;
}

.particle_div .inner_wrapper {
  max-width: 100% !important;
  width: 100% !important;
  padding: 0px !important;
}
.carousel {
  width: 100%;
  max-width: calc(100% - 125px);
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  margin-right: 0px;
}
.carousel .slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.carousel .slides .slide {
  transition: transform 0.5s ease-in-out;
  width: 639px;
  min-height: 414px;
  border-radius: 12px;
  margin-right: 58px;
  padding: 37px 44px;
  background-color: #fff;
  text-align: left;
  display: flex;
  transition: transform 0.5s ease-in-out;
  flex-wrap: wrap;
}
.carousel .slides .slide .title {
  border-bottom: 1px solid #00a68a66;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
}
.carousel .slides .slide .title h4 {
  font-size: 2.25em;
  font-weight: 300;
  line-height: 1;
}
.carousel .slides .slide .title img {
  position: absolute;
  right: 0;
  top: -10px;
}
.carousel .slides .slide .text {
  padding-top: 31px;
}
.carousel .slides .slide p {
  font-size: 1.4375em;
  /*line-height: 33px;*/
  font-weight: 300;
  color: #93a8a5;
}
.access_slider {
  padding-top: 80px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
}
.access_slider .owl-carousel .owl-item img {
  display: block;
  width: auto;
}
.access_slider .slide {
  transition: transform 0.5s ease-in-out;
  width: 40vw;
  /*aspect-ratio: 784.44/414;*/
  /*max-height: 450px;*/
  border-radius: 12px;
  padding: 0 !important;
  background-color: #fff;
  text-align: left;
  display: block;
  transition: transform 0.5s ease-in-out;
  flex-wrap: wrap;
  margin-left: 4.5vw;
  position: relative;
  left: 3.25vw;
  align-items: flex-start;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
}

.owl-carousel .owl-stage {
  margin: 20px 0;
}
.owl-carousel.owl-loaded, .owl-carousel .owl-stage-outer {
  overflow: initial;
}
.access_slider .slide .title {
  padding: 37px 57px 30px;
  position: relative;
  width: 100%;
}
.access_slider .slide .title h4 {
  font-size: 1.875vw;
  font-weight: 450;
  line-height: 1.875vw;
  font-family: "Neue Haas Grotesk Display Pro";
}
.access_slider .slide .title img {
  position: absolute;
  right: 0;
  top: -10px;
}
.access_slider .slide .text {
  padding: 50px 20px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.access_slider .slide .text .img_col {
  width: 8vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
  margin-top: 50px;
  height: 130px;
}
.access_slider .slide p {
  font-size: 1.5em;
  line-height: 30px;
  font-weight: 300;
  color: #232424;
  /*width: calc(100% - 150px);*/
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
  line-clamp: 9;
  -webkit-box-orient: vertical;
  height:  270px;
  letter-spacing: 1px;
}

.access_slider .slide .textWrap {
  height: 270px;
  width: calc(100% - 150px)
}
.access_slider .owl-carousel {
  overflow-y: visible;
}
.access_slider .owl-nav {
  display: none;
}

body::-webkit-scrollbar {
  width: initial;
}

.middle_section .headerWrap {
  display: flex;
  justify-content: space-between;
  padding: 0 calc(6.5% + 16px) 0 7.8%;
}

.middle_section .headerWrap .custom-navigation {
  display: flex;
  justify-content: center;
}

.middle_section .headerWrap .custom-navigation button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7.5px;
  width: 2.5vw;
  height: 2.5vw;
  min-width: 30px;
  min-height: 30px;
  max-width: 50px;
  max-height: 50px;
  background: transparent;
  border: 2px solid #041E19;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.middle_section .headerWrap .custom-navigation button:first-child {
  transform: rotate(180deg);
}

.middle_section .headerWrap .custom-navigation button img {
  width: 150%;
  display: block;
  object-fit: cover;
}

.main.video_bg .newBtn.mobile {
  display: none;
}

.img_banner.animationdone {
  opacity: 0;
}
.start_validation {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  width: fit-content;
  margin: 0 auto;
  border-radius: 50px;
  padding-left: 52px;
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  margin-top: 152px;
}
.start_validation span {
  color: #fff;
}
.start_validation a {
  background-color: #fff;
  border-radius: 50px;
  position: relative;
  right: -1px;
  display: flex;
  margin: -2px;
  margin-left: 1.75em;
  padding: 7px 7px 7px 44px;
  font-size: 1.5em;
  font-weight: 300;

}
.start_validation a img {
  margin-left: 20px;
}
/* .start_validation a:hover {
  color: #fff;
  background-color: #00a68a;
} */

.textslider_colin {
  width: 350px;
  min-height: auto;
  margin-top: 140px;
  height: 350px;
}
.textslider_colin .col {
  height: 100%;
  top: -82px;
}
.textslider_colin .col > div {
  height: 100% !important;
}


.textslider_colin {
  margin-top: 124px;
}
.textslider_colin .col {
  height: 100%;
  top: -111px;
  left: -20px;
}
.particle_div .middle_section h5 {
  font-size: 2.865vw;
  font-weight: 100;
  color: #041E19;
}
.access_slider .slide .title .link img {
  position: relative !important;
  top: 0 !important;
}
.access_slider .slide .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.access_slider .slide .title:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 90%;
  left: 5%;
  height: 1px;
  background: #00a68a;
}


.access_slider .slide .title .img_col,
.access_slider .slide .text .link {
  display: none;
}

/* .onchain_container h4 em { font-weight: 300; } */
.onchain_container video {
  width: 1046px;
  max-width: 100% !important;
  height: 361px;
  object-fit: cover;
}
.onchain_container em {
  color: #00a68a;
}
.btn_global img {
  position: relative;
  top: -0.5px;
}
footer {
  padding-top: 80px;
}
footer .top-footer {
  padding: 0 69px 77px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
}
footer .top-footer ul {
  list-style: none;
  width: 196px;
}
footer .top-footer ul li {
  margin-bottom: 15px;
  line-height: 1;
}
footer .top-footer ul li:last-child {
  margin-bottom: 0;
}
footer .top-footer ul li a {
  color: #e2eae9;
  font-size: 1em;
  font-weight: 100;
  line-height: .82;
}
.footer {
  background-image: url(./assets/images/footer.jpg) !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer .brand-logo {
  margin-right: 315px;
}

.validator_banner {
  text-align: center;
  padding: 84px 40px 0;
}
.validator_banner .animatedTexts {
  min-height: 376px;
}

.validator_banner p.heading,
.network_wrapper h2 {
  color: #041E19;
  font-size: 2.75em;
  font-style: normal;
  font-weight: 350;
  margin-bottom: 60px;
}

.validator_banner h1 {
  font-weight: 300;
  font-size: 3.75em;
  line-height: 70px;
  margin-bottom: 37px;
}
.validator_banner .newBtn {
  margin: 60px auto -108px;
  z-index: 10 !important;
  position: relative !important;
}
.validator_banner .newBtn.mobile {
  display: none;
}
.validator_banner p.heading span {
  color: #00a68a;
}
.validator_banner p {
  max-width: 890px;
  margin: 0 auto;
  color: #041E19;
  text-align: center;
  font-size: 1.375em;
  font-weight: 300;
  line-height: 30px; /* 136.364% */
  letter-spacing: 0.44px;
}
.validator_banner p.secondP {
  font-weight: 450;
  margin-top: 120px;
}
.validator_banner .learn_more {
  background-color: #00a68a;
  padding: 7.5px;
  padding-left: 35px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  font-size: 1.375em;
  font-weight: 300;
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 60px;
}

.validator_banner .learn_more img {
  margin-left: 14px;
}
.validator_banner .learn_more:hover {
  opacity: 0.7;
}
.restaker_graph {
  margin-top: 45px;
  position: relative;
  z-index: 1;
}

.restaker_graph:after {
  content: "";
  position: absolute;
  width: 100%;
  background: linear-gradient(0deg, #FFF 39.3%, rgba(255, 255, 255, 0.00) 71.7%);
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 30%;
}


.network_wrapper {
  position: relative;
  z-index: 8;
  text-align: center;
  padding: 50px 0 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video_bg .newBtn {
  position: relative;
  z-index: 11;
}

/*.network_wrapper:after {*/
/*  position: absolute;*/
/*  content: "";*/
/*  width: 100%;*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  height: 750px;*/
/*  z-index: -1;*/
/*  background-color: #007864;*/
/*}*/

.network_wrapper * {
  color: #041E19;
  letter-spacing: 1px;
  font-weight: 300;
  line-height: 1.2;
}

.network_wrapper .video-wrap:after {
  background-image: linear-gradient(
          180deg,
          rgba(255,255,255,1) 0%,
          rgba(255,255,255,0.5) 300px,
          rgba(255,255,255,0.5) 80%,
          rgba(255,255,255,1) 100%
  );
}

.video-wrap:after {
  position: absolute;
  content: "";
  /*background-image: linear-gradient(*/
  /*        180deg,*/
  /*        rgba(255,255,255,1) 0%,*/
  /*        rgba(255,255,255,0.75) 30%,*/
  /*        rgba(255,255,255,0.75) 70%,*/
  /*        rgba(255,255,255,1) 100%*/
  /*);*/
  /*background-image: linear-gradient(*/
  /*        180deg,*/
  /*        rgba(255,255,255,1) 0%,*/
  /*        rgba(255,255,255,0.4) 10%,*/
  /*        rgba(255,255,255,0.15) 20%,*/

  /*        rgba(255,255,255,0.15) 80%,*/
  /*        rgba(255,255,255,0.4) 90%,*/
  /*        rgba(255,255,255,1) 100%*/
  /*);*/
  background-image: linear-gradient(
          180deg,
          rgba(255,255,255,1) 0%,
          rgba(255,255,255,0.2) 20%,
          rgba(255,255,255,0) 50%,
          rgba(255,255,255,0.2) 80%,
          rgba(255,255,255,1) 100%
  );
  background-position: center;
  width: 100%;
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;
}

.network_wrapper .animatedTexts {
  min-height: 484px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.network_wrapper .animatedTexts > div {
  width: 100%;
}
.network_wrapper h3 {
  font-size: 2.8125em;
  font-weight: 300;
  margin: 0 auto 60px;
  max-width: 890px;
  width: 80%;
}
.network_wrapper p {
  font-size: 1.375em;
  /*margin-bottom: 30px;*/
  letter-spacing: 0.44px;
  max-width: 890px;
  width: 80%;
  margin: 0 auto;
}
.network_wrapper h2 {
  margin: 100px auto 64px;
  font-size: 3.75em;

}
.join_network_btn img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.join_network_btn {
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 51.044px;
  padding: 7px 8px 7px 20px;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
}
/* .join_network_btn:hover {
  background-color: #00a68a;
  color: #ffffff;
} */
.contain_img {
  margin-top: -340px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 150px;
}
.contain_img .newBtn {
  position: absolute;
  top: 5px;
  transform: translateX(-50%);
  left: 50%;
}
.contain_img img {
  max-width: 100%;
}

.particle_div .btn {
  background-color: #00a68a;
  padding: 7.5px;
  padding-left: 35px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  font-size: 1.375em;
  font-weight: 300;
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin: 120px auto 0;
}

.particle_div .newBtn {
  margin: 120px auto 0;
}

.particle_div .btn img {
  margin-left: 20px;
  border-radius: 50%;
}


.slideText {
  opacity: 0.3;
  max-height: 0;
  overflow: hidden;
  transition: opacity 1s ease-in, max-height 12s;
}

.slideText.second {
  transition-delay: 1.5s;
}

.lottieBtn {
  width: 260px;
  display: flex;
}

.newBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-left: 45px;
  border-radius: 60px;
  background: #041E19;
;
  width: fit-content;
  color: #FFF;
  font-size: 1.375em;
  letter-spacing: 1px;
  transition: all .25s;
}

.newBtn:hover {
  opacity: .8;
}

.newBtn div[role="button"] {
  width: 45px !important;
  height: 45px !important;
  margin-left: 25px !important;
}

.newBtn .staticArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-left: 25px;
}

.newBtn .staticArrow img {
  width: 43px;
}

* div[role="button"] {
  pointer-events: none;
}

@media (max-width: 1750px) {
  .inner_wrapper {
    padding: 0px 20px;
  }
  .particle_div .middle_section .infotext_box {
    max-width: 960px;
    padding-bottom: 80px;
  }
  .onchain_container {
    padding-top: 160px;
    padding-bottom: 100px;
  }
  .subscribe_wrapper .subscribe_inner {
    max-width: 580px;
  }

  .inner_wrapper {
    padding: 0px 90px;
  }
  .particle_div .middle_section h5 {
    margin-bottom: 50px;
  }
  .access_slider .slide .text {
    padding: 30px 20px 20px;
  }
  .access_slider .slide .textWrap {
    width: calc(100% - 120px);
  }
  .access_slider .slide .text .img_col {
    width: 120px;
  }
  .access_slider .slide .text .img_col img {
    max-width: 90px;
  }
  .access_slider .slide .title {
    padding: 37px 40px 30px;
  }
  .start_validation {
    margin-top: 110px;
  }

}

.video_bg {
  position: relative;
}

.video-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-wrap.one:after {
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 5%, rgba(255,255,255,0.5) 100%);
}

.video-wrap video {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*filter: brightness(.8);*/
}

@media (max-width: 1499px) {
  /*.banner .count_blog {*/
  /*  width: 662px;*/
  /*}*/

  .access_slider .slide {
    /*min-height: 414px;*/
    padding: 30px 30px;
  }
  .access_slider .slide p {
    font-size: 1.375em;
  }
  .subscribe_wrapper {
    max-width: calc(100% - 180px);
  }
  .subscribe_wrapper h4 {
    margin-bottom: 60px;
  }

  .footer .brand-logo {
    margin-right: 16%;
  }
  .for {
    width: 60px;
  }
}
@media (max-width: 1399px) {


  .particle_div .middle_section {
    padding-top: 100px;
    padding-bottom: 60px;
  }
  .access_slider .slide p {
    font-size: 1.375em;
    padding-left: 0;
  }

  .inner_wrapper {
    padding: 0px 40px;
  }
  .access_slider .slide .text {
    padding: 30px 20px 20px;
  }
  .access_slider .slide .text .img_col {
    width: 100px;
  }
  .access_slider .slide .text .img_col img {
    max-width: 80px;
  }
  .access_slider .slide .title {
    padding: 20px 30px;
  }
  .particle_div .middle_section h5 {
    margin-bottom: 20px;
  }
  .subscribe_wrapper {
    max-width: calc(100% - 80px);
  }
  footer {
    padding-top: 50px;
  }
  footer .top-footer {
    padding: 0 69px 50px;
  }
  .validator_banner {
    padding: 60px 40px 60px;
  }
  .network_wrapper {
    padding: 70px 0 120px;
  }
}
@media (max-width: 1360px) {
  .subscribe_wrapper {
    max-width: 90%;
  }
  .onchain_container video {
    max-width: 100%;
  }
  .network_wrapper h2 {
    padding-bottom: 30px;
  }
  .join_network_btn {
    padding: 5px 6px 5px 20px;
  }
  .contain_img {
    margin-top: 30px;
  }
}
@media (max-width: 1279px) {
  .textslider_colin {
    height: 290px;
  }
  .textslider_colin .col {
    left: -15px;
    top: -70px;
  }
  .restaker_graph {
  }
  .restaker_graph img {
    max-width: 100%;
  }
  .validator_banner h1 {
    margin-bottom: 20px;
  }
  .validator_banner .learn_more {
    margin-top: 50px;
  }
  .validator_banner .lottieBtn {
    margin-top: 50px;
  }
}
@media (max-width: 1199px) {

  .onchain_container .textWrap.second {
    height: 90px;
  }
  footer {
    padding: 26px;
  }
  footer .top-footer {
    padding: 0 30px 25px;
    margin-bottom: 15px;
  }
  footer .bottom-footer {
    padding: 0 30px;
  }
  .particle_div .middle_section {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .particle_div .middle_section .infotext_box h4 {
    margin-bottom: 30px;
  }
  .particle_div .middle_section .infotext_box {
    padding-bottom: 50px;
  }
  .btn_global {
    font-size: 1.125em;
    padding: 4px 6px 4px 25px;
  }
  .onchain_container h4 {
    font-size: 2em;
  }
  .subscribe_wrapper h4 {
    font-size: 1.75em;
  }
  .subscribe_wrapper {
    padding: 50px 10px;
    margin-bottom: 60px;
  }
}
@media (max-width: 1023px) {

  header {
    padding: 15px 20px;
  }


  .onchain_container video {
    min-width: 100%;
    height: 340px;
  }
  .footer .brand-logo {
    margin-right: 10%;
  }
  footer .top-footer ul {
    width: 172px;
  }
  .network_wrapper:after {
    height: 500px;
  }

}
@media (max-width: 767px) {
  html {
    scroll-snap-type: initial;
  }
  footer {
    padding: 25px 15px;
  }
  footer .top-footer {
    padding: 0px 15px 25px;
    margin-bottom: 25px;
    text-align: center;
  }
  footer .bottom-footer {
    padding: 0;
    flex-wrap: wrap;
    gap: 20px 0;
    justify-content: flex-start;
  }
  .particle_div .middle_section {
    padding: 30px 0px;
  }
  .particle_div .middle_section .infotext_box h4 {
    /*font-size: 22px;*/
    margin-bottom: 15px;
  }
  .btn_global {
    font-size: 15px;
  }
  .btn_global img {
    max-width: 38px;
  }
  .onchain_container {
    padding: 140px 0;
  }
  .subscribe_wrapper {
    max-width: 95%;
    padding: 50px 20px;
  }
  .subscribe_wrapper h4 {
    margin-bottom: 40px;
  }
  .subscribe_wrapper .from_group .newBtn {
    position: relative;
    margin: 20px auto 0;
  }

  body {
    padding-top: 60px;
  }

  header .right_col {
    position: fixed;
    left: 0;
    height: 330px;
    width: 100%;
    background-color: #fff;
    top: 50px;
    box-shadow: 0px 10px 10px #27b49c52;
    z-index: 9999;
    display: block;
    padding-top: 25px;
    transform: translateX(-110%);
    -webkit-transform: translateX(-110%);
    -o-transform: translateX(-110%);
    -moz-transform: translateX(-110%);
    -o-transform: translateX(-110%);
    transition: 0.5s all ease;
    -webkit-transition: 0.5s all ease;
    -o-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
  }
  header .right_col.active_menu {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -o-transform: translateX(0);
    -moz-transform: translateX(0);
    box-shadow: 0px 4px 4px 0px rgba(0, 166, 138, 0.10);
    height: 330px;
    padding: 0 28px;
  }

  header {
    top: 0px !important;
    max-width: 100vw;
    padding: 5px 28px;
  }
  header.active {
    transform: translateY(0) !important;
  }

  header ul {
    margin: 30px 0;
  }
  header ul li {
    margin-right: 0;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
  header ul li a {
    line-height: 30px;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25em;
  }
  header .wallet {
    margin: 0;
    padding: 0px 0px;
    max-width: 150px;
    display: block;
    text-align: center;
    line-height: 35px;
  }
  body .mobile_toggle {
    display: block;
  }
  .toggle-mnu {
    display: block;
    width: 28px;
    height: 28px;
    margin-top: 9px;
  }
  .toggle-mnu span:after,
  .toggle-mnu span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
  }
  .toggle-mnu span:after {
    top: 18px;
  }
  .toggle-mnu span {
    position: relative;
    display: block;
  }
  .toggle-mnu span,
  .toggle-mnu span:after,
  .toggle-mnu span:before {
    width: 100%;
    height: 2px;
    background-color: #041E19;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px;
  }
  .toggle-mnu[data-class="on"] span {
    background-color: transparent;
  }
  .toggle-mnu[data-class="on"] span:before {
    transform: rotate(45deg) translate(-1px, 0px);
    -webkit-transform: rotate(45deg) translate(-1px, 0px);
    -o-transform: rotate(45deg) translate(-1px, 0px);
    -moz-transform: rotate(45deg) translate(-1px, 0px);
  }
  .toggle-mnu[data-class="on"] span:after {
    transform: rotate(-45deg) translate(6px, -7px);
    -webkit-transform: rotate(-45deg) translate(6px, -7px);
    -o-transform: rotate(-45deg) translate(6px, -7px);
    -moz-transform: rotate(-45deg) translate(6px, -7px);
  }
  .textslider_colin {
    width: 280px;
  }
  .particle_div .middle_section .infotext_box h4 {
    padding: 0px 20px;
  }
  .access_slider {
    padding-top: 50px;
  }

  .access_slider .slide .title h4 {
    font-size: 1.25em;
    line-height: 1.5;
  }
  .access_slider .slide .title img {
    right: 0;
    top: -5px;
    max-width: 40px;
    max-height: 40px;
  }
  .access_slider .slide .text {
    padding: 30px 20px 24px;
    flex-direction: column;
    justify-content: space-between;
    height: 314px;
  }
  .textslider_colin .col h4 {
    font-size: 1.25em;
    line-height: 1;
    margin-bottom: 4px;
  }
  .textslider_colin .col a {
    font-size: 0.875em;
    line-height: 1;
  }

  .particle_div .middle_section .headerWrap {
    padding: 0 20px;
  }

  .particle_div .middle_section h5 {
    margin-bottom: 20px;
  }
  .particle_div .middle_section h5 {
    font-size: 1.875em;
  }

  .access_slider .slide .text .img_col {
    display: none;
  }
  .start_validation {
    margin-top: 40px;
    flex-wrap: wrap;
    padding: 0;
    max-width: 280px;
    border: none;
    font-size: 1.5em;
  }
  .start_validation a {
    margin-left: 0;
    padding: 5px 5px 5px 18px;
    font-size: 1.125em;
    margin-top: 15px;
    line-height: 1.5;
  }
  .start_validation a img {
    margin-left: 7px;
    max-width: 30px;
  }
  .subscribe_wrapper h4 {
    font-size: 1.3125em;
    margin-bottom: 20px;
    line-height: 1.3;
  }
  .subscribe_wrapper .from_group .in_field {
    height: 45px;
  }
  .subscribe_wrapper .from_group .submit_btn {
    margin-top: 10px;
    height: 45px;
    font-size: 1.125em;
    transform: scale(0.9);
    position: absolute;
    top: -10px;
    right: -8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  .subscribe_wrapper .from_group .submit_btn img {
    display: none;
  }
  .access_slider .slide .title {
    padding: 16px 20px !important;
  }
  .access_slider .slide .title .link {
    display: none;
  }
  .access_slider .slide .title .img_col {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .access_slider .slide .title .img_col img {
    all: unset;
    display: block;
    /*width: 100%;*/
    height: 100%;
  }
  .access_slider .slide p {
    font-size: 1em;
    line-height: 20px;
    padding-left: 0;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 180px;
    -webkit-line-clamp: 11; /* number of lines to show */
    line-clamp: 11;
    -webkit-box-orient: vertical;
  }
  .access_slider .slide .text .img_col img {
    display: none;
  }

  .access_slider .slide .text .link {
    display: block;
    margin-top: 50px;
    margin-left: auto;
  }

  .access_slider .slide {
    width: 70vw;
    padding: 20px 13px;
    margin-left: 16px;
    left: 0;
    min-height: unset;
    height: unset;
  }

  .textslider_colin .col > div {
    height: 100% !important;
  }
  .textslider_colin {
    height: 200px;
  }
  .textslider_colin .col {
    left: -15px;
    top: -30px;
  }
  footer {
    padding: 40px 28px 28px;
  }
  footer .copyright {
    width: 100%;
    text-align: left;
  }
  footer .top-footer {
    padding: 0px 0px 10px;
    margin-bottom: 20px;
  }
  .footer .brand-logo {
    margin-right: 0;
    width: 100%;
    text-align: left;
    margin-bottom: 50px;
  }
  .footer .top-footer .col {
    width: 50%;
  }
  .footer .top-footer .col.bottom {
    margin-top: 40px;
  }
  .footer .top-footer .col:last-child {
    width: 100%;
  }
  .footer .top-footer .col ul {
    width: 100%;
    text-align: left;
  }
  footer .top-footer ul li a {
    font-size: 0.875em;
    line-height: .8;
  }
  footer .top-footer ul li {
    margin: 10px auto !important;
  }
  .subscribe_wrapper {
    max-width: calc(100% - 40px);
    padding: 40px 20px;
    margin-bottom: 40px;
  }
  .onchain_container .learn_more img {
    margin-left: 14px;
    max-width: 28px;
  }
  .onchain_container .learn_more {
    padding: 0px 7.5px;
    padding-left: 35px;
    font-size: 1.125em;
    margin-top: 30px;
    line-height: 3;
  }
  .onchain_container video {
    height: 210px;
  }
  .access_slider .slide .title .link img {
    max-width: 20px;
  }


  .network_wrapper h2 {
    font-size: 1.875em;
    line-height: 1.2;
    margin: 100px auto 0;
  }
  .join_network_btn {
    font-size: 1.125em;
    padding: 5px 6px 5px 20px;
  }
  .join_network_btn img {
    max-width: 36px;
  }
  .network_wrapper:after {
    height: 320px;
  }
  .network_wrapper {
    padding-top: 50px;
  }
  .validator_banner {
    padding: 30px 20px 30px;
  }
  .validator_banner h1 {
    font-size: 2em;
    line-height: 1.1;
    margin-bottom: 20px;
  }
  .validator_banner h1 br {
    display: none;
  }
  .validator_banner p,
  .main.video_bg p {
    max-width: 890px;
    font-size: 1em;
    line-height: 1.25;
  }
  .validator_banner .learn_more {
    margin-top: 30px;
    font-size: 1.125em;
    line-height: 2.7;
    padding: 0px 7.5px 0px 20px;
  }
  .validator_banner .learn_more img {
    max-width: 31px;
    margin-left: 10px;
  }
  .restaker_graph {
    margin-top: 65px;
  }
  .network_wrapper {
    padding: 40px 0 30px;
  }
  .access_slider .slide .textWrap {
    width: 100%;
  }
  .network_wrapper h3,
  .validator_banner p.heading {
    font-size: 2em;
  }
  .connect_blockchain .lottieBtn,
  .lottieBtn {
    width: 150px;
  }
  .newBtn {
    font-size: 1rem;
    line-height: 1;
    padding-left: 25px;
  }
  .newBtn .staticArrow {
    width: 30px;
    height: 30px;
    margin-left: 15px;
  }

  .newBtn .staticArrow img {
    width: 28px;
  }

  .newBtn > div[role="button"] {
    width: 30px !important;
    height: 30px !important;
    margin: 0 auto 0 15px !important;
  }
  .onchain_container .newBtn {
    margin-top: 150px;
  }
  .middle_section .headerWrap .custom-navigation button img {
    width: 200%;
  }
  .network_wrapper .newBtn,
  .contain_img .newBtn {
    display: none;
  }
  .main.video_bg .contain_img {
    margin: 0 0 100px;
  }
  .main.video_bg .newBtn.mobile {
    display: flex;
    display: flex;
    margin: 50px auto;
  }
  .for {
    width: 50px;
  }
  .validator_banner .newBtn {
    display: none;
  }
  .validator_banner .newBtn.mobile {
    display: flex;
    margin: 50px auto;
  }
  .validator_banner .animatedTexts {
    min-height: 400px;
  }
  .onchain_container h4 {
    font-size: 1.9em;
  }
  .inner_wrapper {
    padding: 0 20px;
  }
}

